@import "partials/_variables";
@import "partials/_mixins";

.happiness-survey {
  &__previously-reviewed {
    font-size: 14px;

    &__body {
      font-style: italic;
      color: $color-content-secondary;
      padding-top: 16px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: inherit;

    @include mobile-breakpoint {
      height: calc(var(--vh, 1vh) * 100px);
    }

    @include tablet-and-desktop-breakpoint {
      min-height: $happiness-survey-reviews-modal-min-height;
    }
  }

  &__modal {
    // Do not remove the !important below, they are to override the inline styling of the modal
    // sass-lint:disable no-important
    padding: 0 !important;
    // sass-lint:enable no-important
  
    // Do not remove the two !important below, they are to override the inline styling of the modal
    @include mobile-breakpoint {
      // sass-lint:disable no-important
      width: 100% !important;
      // sass-lint:enable no-important
      height: calc(var(--vh, 1vh) * 100px);
    }
  
    @include tablet-and-desktop-breakpoint {
      min-height: $happiness-survey-reviews-modal-min-height;
      height: 75vh;
    }

    // custom scrollbar on desktop (chrome + safari)
    @include tablet-and-desktop-breakpoint {
      ::-webkit-scrollbar {
        width: 12px;

        &-track {
          background: $color-background-secondary;
          margin: 10px 0 87px 0;
          border-radius: 10px;
        }

        &-thumb {
          background: $color-medium-gray;
          border-radius: 10px;

          &:hover {
            cursor: pointer;
            background: darken($color-medium-gray, 20%);
          }
        }
      }
    }

    .photo-uploader-title {
      font-weight: 700;
    }
  }

  &__title-screen {
    padding: 24px 16px 16px;
    overflow: auto;
    margin-bottom: 104px;

    .grid-label-and-checkbox {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title-screen-checkboxes {
    padding: 24px 16px 16px;
    overflow: auto;
    margin-bottom: 104px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .double-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
      gap: 16px;
    }
  }

  &__final-screen {
    padding: 32px 24px;
    overflow: auto;
    margin-bottom: 82px;

    h2 {
      font-size: 1.25rem;
      font-weight: 300;
      padding: 32px 0 24px;
      border-top: 1px solid $color-border-opaque;
    }

    p {
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-content-secondary;
    }

    .return-info {
      margin-bottom: 24px;
      text-align: center;
      font-size: 0.875rem;

      .inner-date {
        font-weight: 700;
      }
    }

    .return-help {
      margin: 0 0 24px;
    
      button {
        display: block;
        margin: auto;
        height: 44px;
        
        &.hpu-button {
          width: 278px;
          border: 1px solid;
        }

        &.ups-button {
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }

    .review-link {
      display: block;
      font-weight: 600;
      font-size: 0.875rem;
      text-decoration: underline;
      margin-top: 37px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__content {
    padding: 24px;
    gap: 24px;
    margin-bottom: 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    padding: 0 8px 24px;
    text-align: center;

    &.start-screen {
      border-bottom: 1px solid $color-border-opaque;
      text-align: left;
      padding: 0 0 16px 0;
    }

    &.start-screen,
    &.final-screen {
      h1 {
        margin-bottom: 8px;
      }
    }

    h1 {
      font-weight: 300;
      font-size: 1.75rem;
      color: $color-content-primary;
      margin-bottom: 12px;
    }

    p {
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-content-secondary;
    }
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid $color-border-opaque;
  }

  &__other-fit-options-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .label-container {
      gap: 8px;
    }
  }
}

.survey-controls {
  &__container {
    position: sticky;
    top: 0;
    min-height: 44px;
    padding: 0 16px;
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-areas: "back header skip";
    align-items: center;
    border-bottom: 1px solid $border-color;
    background-color: $color-background;
  }

  &__header {
    grid-area: header;
    text-align: center;
    cursor: pointer; 
    // clear when h4 styles are applied
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; 
    letter-spacing: -0.2px;
  }

  &__back {
    grid-area: back;
    justify-self: flex-start;
    cursor: pointer;
    height: 18px;
  }

  &__skip {
    grid-area: skip;
    justify-self: flex-end;
    // replace with Typography Button mixin
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; // 14px
    letter-spacing: 0.28px;
    text-decoration: underline;
  }
}

.survey-item-banner {
  &__container {
    height: 162px;
    display: flex;
    border-bottom: 1px solid $border-color;
  }

  &__thumbnail {
    width: 108px;
    height: 162px;
  }

  &__content {
    display: flex;
    padding: 12px 27px;
    padding-right: 8px;
    flex-direction: column;
    text-align: left;
    gap: 16px;

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .label,
    .value {
      // replace with Body Text 2 mixin 
      // font-family: Plain;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; 

    }

    .label {
      color: $color-content-primary;
    }

    .value {
      color: $color-content-secondary;
    }

    .eyebrow {
      color: $color-content-secondary;
      // replace with Eyebrow mixin
      // font-family: "Pitch Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; 
      letter-spacing: 0.28px;
      text-transform: uppercase;
    }
  }
}

.happiness-survey-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  background-color: $color-white;
  border-top: 1px solid $color-border-opaque;

  .happiness-survey-footer {
    margin: 16px 0;
    width: 200px;
    text-align: center;

    &.with-secondary-footer {
      margin-bottom: 8px;
    }
  }

  .happiness-survey-secondary-footer {
    color: $color-content-primary;
    text-decoration: underline;
    margin-bottom: 16px;

    &.skip {
      font-weight: 500;
    }

    &:disabled {
      color: $color-content-gray-disabled;
    }
  }
}
