@import "partials/_variables";
@import "../inputs/inputs.module.scss";

.fit-profile-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 16px;
}

.vertical-border {
  height: 14px;
  border-left: 1px solid $color-content-secondary;
}

.label-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header-container {
  display: flex;
  justify-content: space-between;

  button {
    text-decoration: underline;
  }
}

.header {
  @include header-label-style;
}

.sub-header {
  @include sub-header-label-style;
}

.fit-profile-values-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;

  // replace with @include body-text-2 
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.fit-profile-inputs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  gap: 8px;
}

.inline-input-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  select {
    width: 235px;
  }
}

.input-label {
  @include header-label-style;
}