@import "partials/_mixins";
@import "partials/_variables";
@import "../../../../../../stylesheets/design-system/typography/typography-mixins";

@mixin selected-style {
  background-color: $color-background-inverse;
  color: $color-content-inverse; 
}

// replace once mixin is inlcuded in /typographys body-size-2
@mixin body-size-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; // 18.2px 
}

@mixin header-label-style {
  @include body-size-2;
}

@mixin sub-header-label-style {
  @include body-size-2;
  color: $color-content-secondary;
}

// Input labels
.header-label {
  @include header-label-style;
}

.header-label-container {
  display: flex;
  gap: 8px;
  width: 100%; 
  justify-content: space-between;
}

.sub-header-label {
  @include sub-header-label-style;
}

// General Input styles
.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input,
  input[type="text"],
  textarea {
    @include body-size-2;
  }

  &.inline {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "label input";
    gap: 4px;
    align-items: center;
    width: 100%;
    height: 48px;
  }

  &.title-screen-input {
    margin-top: 24px;
  }
}

.label-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%; 
}

.button-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .selection-button {
    display: flex;
    background-color: $color-white;
    color: $color-content-primary;
    border: 1px solid $color-content-primary;
    padding: 8px 16px;
    flex: initial;
    flex-shrink: 0;

    &.selected {
      background-color: $color-content-primary;
      color: $color-white;
    }
  }
}

.accordion-container {
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 16px;
  }

  &-cta { 
    width: 14px;
    height: 14px;

    &:before, 
    &:after {
      background: $color-primary-copy;
      content: "";
      width: 12px;
      height: 2px;
      position: absolute;
      top: 48%;
      right: 0;
    }

    &.closed:after {
      transform: rotate(90deg);
    }
  }
}

.checkbox-label {
  text-transform: unset;
  font-size: 0.875rem;
  letter-spacing: unset;
  line-height: unset;
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  color: $color-content-secondary;
  cursor: pointer;
  gap: 8px;
}

.checkboxes {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 24px;
}

.double-checkbox {
  text-transform: unset;
  font-size: 0.875rem;
  letter-spacing: unset;
  line-height: unset;
  display: flex;
  align-items: center;
  color: $color-content-secondary;
  cursor: pointer;
  gap: 8px;
}

.checkbox-selection {
  svg {
    width: 24px;
    height: 24px;
    rect {
     width: 22.5px; height: 22.5px; stroke: #1C1C1C; stroke-width: 1.5;
    }
    path {
    stroke: #1C1C1C;
    stroke-width: 2.25;
    stroke-linecap: round;
    stroke-linejoin: round;
    }
  }
}

.text-field {
  margin: 0;

  input,
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="date"],
  input[type="search"],
  input[type="password"],
  select {
    border: 1px solid $color-border-opaque;

    &:focus {
      border: 1px solid $color-border-opaque;
    }
  }

  &.error,
  &.error:focus {
    border: 1px solid $border-negative;
    
    &::placeholder {
      color: $border-negative;
      opacity: 1; // Firefox 
    }
  }
}

.product-grid {
  margin-top: 24px;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  justify-content: space-between;

  &.smaller-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .clickable-grid-item-container {
    display: flex;
    position: relative;
    justify-content: center;
    aspect-ratio: 0.66667;

    svg {
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }

  .grid-item {
    width: 100%;
    aspect-ratio: 0.66667;

    &.item-selected {
      outline: 1.5px $color-content-primary solid;
      outline-offset: -1.5px;
    }
  }

  .grid-item-disabled {
    width: 100%;
    aspect-ratio: 0.66667;
    opacity: 0.6;
  }
}

.product-grid-double-checkbox{
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  justify-content: space-between;

  &.smaller-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .clickable-grid-item-container {
    display: flex;
    position: relative;
    justify-content: center;
    aspect-ratio: 0.66667;

    svg {
      position: absolute;
      bottom: 8px;
      right: 8px;
    }
  }

  .grid-item {
    width: 100%;
    aspect-ratio: 0.66667;

    &.item-selected {
      outline: 1.5px $color-content-primary solid;
      outline-offset: -1.5px;
    }
  }

  .grid-item-disabled {
    width: 100%;
    aspect-ratio: 0.66667;
    opacity: 0.6;
  }
}

// specific inputs
.star-rating {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px; 
  }

  &__input {
    display: flex;
    height: 24px;

    svg {
      height: 24px;
    }
    
    label {
      margin: 0;
    }
  }

  &__value-label {
    @include body-size-2;
    color: $color-content-positive;
  }
}

.capsule-select {
  &__container {
    display: flex;
    min-height: 44px;
    width: 100%;
    position: relative;
    background-color: $color-background;
    overflow: hidden;

    button {
      border-left: none;
    }

    button:first-child {
      border-left: 1px solid $border-primary-color;
    }
  }

  &__title {
    border: 1px solid $border-primary-color;
    width: 100%;
    min-height: 44px;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    line-height: 0;
    align-items: center;

    &.open {
      left: 0;
    }

    &.hidden {
      left: 100%;
    }

    @include mobile-breakpoint {
      height: 44px;
      padding: 15px 12px;
    }

    &:hover,
    &.selected {
      @include selected-style;
    }

    &.selected {
      justify-content: space-between;
    }
  }

  &__option {
    &-input {
      border: 1px solid $border-primary-color;
      min-height: 44px;
      background-color: $color-background;
      padding: 16px 19px;
      text-align: center;
      width: 100%;
      line-height: 0;

      @include mobile-breakpoint {
        padding: 15px 12px;
        text-wrap: nowrap;
      }

      &.options-only {
        background-color: $color-background;
        &:hover,
        &.selected {
          @include selected-style;
        }
      }

      &:hover,
      &.selected {
        @include selected-style;
      }

    }

    &-container {
      display: flex;
      width: 100%;
      position: absolute;
      top: 0;

      &.open {
        left: 0;
      }

      &.hidden {
        left: 100%;
      }
    }
  }
}

.dropdown-select {
  width: 100%;
  height: 48px;

  &.double-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "input input";
    gap: 4px;
  }

  .input {
    grid-area: "input";
  }
}

$button-width: 20px;
$button-height: 20px;
$desktop-image-width: 95px;
$desktop-image-height: 120px;
$mobile-image-width: 90px;
$mobile-image-height: 120px;

@mixin icon-image-size {
  width: $desktop-image-width;
  height: $desktop-image-height;
  @include mobile-breakpoint {
    width: $mobile-image-width;
    height: $mobile-image-height;
  }
}

@mixin icon-container-size {
  width: calc($desktop-image-width + $button-width); // we want the width of one button because there is one button on each side and we want the image to start/end at the half way point of both buttons
  height: calc($desktop-image-height + ($button-height/2));
  @include mobile-breakpoint {
    width: calc($mobile-image-width + $button-width);
    height: calc($mobile-image-height + ($button-height/2));
  }
}

.photo-uploader {
  &-container {
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-button-container {
    margin: 0;
    margin-bottom: 8px;
    display: flex;
    width: 183px;
    height: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-background-secondary;
  }

  &-input {
    display: none;
  }
}

.added-photos-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  // the container for the remove/rotate buttons for the uploaded image
  .photo-image-container {
    position: relative;
    display: flex;

    .rotate-button,
    .remove-button {
      border-radius: 50%;
      height: $button-height;
      width: $button-width;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      color: $color-primary-copy;
      background-color: $color-background;
      cursor: pointer;
      z-index: 1;
    }

    .remove-button {
      right: 0;
      left: unset;
    }
  }

  // the styles for the actual uploaded image
  .photo-image {
    @include icon-container-size;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      @include icon-image-size;
      border-radius: 4px;
      object-fit: cover;
    }

    &.loading {
      opacity: 0.2;
      // Because opacity affects the stacking order ಠ_ಠ
      z-index: -1;
    }

    &.rotate {
      &-90 {
        transform: rotate(90deg);
      }
    
      &-180 {
        transform: rotate(180deg);
      }
    
      &-270 {
        transform: rotate(270deg);
      }
    }

  }

  // special loading thumbnail
  .loading-thumbnail {
    width: 95px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
    }
  }
}

.file-size-error-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .heading,
  .subtext {
    color: $color-clearance;
  }

  .heading {
    font-size: 17px;
    font-weight: 600;
  }

  .subtext {
    @include font-xs;
    color: $color-clearance;
  }
}
